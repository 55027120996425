import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocationCard } from "../components/cards/locationCard";
import { NextMeetCard } from "../components/cards/nextMeetCard";
import { meetings2024 } from "../assets/data/meetings2024";
import { CalendarView } from "../components/other/CalendarView";
import { useWindowDimensions } from "../utils/mediaQuery";

export const Schedule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const nextDate = meetings2024.filter((i) => i.date > new Date())[0];

  return (
    <Stack>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "start",
          gap: "2rem",
          width: "100%",
          padding: "2rem",
        }}
      >
        <Typography
          variant={width > 768 ? "h2" : "h3"}
          textAlign={"center"}
          color={theme.palette.primary.main}
        >
          Schedule
        </Typography>
        <NextMeetCard
          locationName={nextDate.locationName}
          date={nextDate.date}
          title="Next meeting"
        />
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          width: "100%",
          padding: "2rem",
        }}
      >
        <Box>
          <Typography>
            Come join us for one of our knitting and crafts sessions.
          </Typography>
          <Typography>
            We meet regularly on Wednesday afternoons and Sundays afternoons. We
            also have evening sessions sometimes when we're running a workshop
            series.
          </Typography>
        </Box>
        <Typography>
          <strong>Wednesday</strong>: During term times, we meet at the Grange
          Hall in the center of Radcliffe on trent. We rent a room so there's
          plenty of space for our projects and to discuss anything and
          everything. During school holidays, we meet in Costa in the centre of
          the village where we have a catch up and enjoy a beverage with some
          light crafts for the ones that fancy it.
        </Typography>
        <Typography>
          <strong>Sunday</strong>: Once a month at present, generally the 1st
          Sunday of the month, we meet at Costa in the centre of the village and
          we socialise while exploring further crafts including sewing and
          quilting.
        </Typography>
        <Typography>
          <strong>Workshops</strong>: We run a couple of series per year, these
          would be tailored to what our members would like to learn and have
          asked for, or it might linked to a village show category, to support
          everyone develop their skills and be able to enter items in the show.
          The current ongoing workshop series is{" "}
          <strong>
            Basic Crochet: All the tools to get started (7 sessions), from
            November 2024 to February 2025
          </strong>
          .
        </Typography>
        <Divider variant="middle" />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            width: "100%",
          }}
        >
          <Typography variant={"h4"} color={theme.palette.primary.main}>
            Full calendar of meetings
          </Typography>
          <CalendarView />
        </Box>

        <Divider variant="middle" />
        <Typography variant={"h4"} color={theme.palette.primary.main}>
          Locations
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={"2rem"}
        >
          <LocationCard
            imageName={"grange-building"}
            locationName={"The Grange"}
            address={
              "Dowson room, Vicarage Ln, Radcliffe on Trent, Nottingham NG12 2FB"
            }
            day={"Wednesday"}
            timeslot={"1.30pm - 3.00pm"}
            mapLink={
              "https://www.google.com/maps/place/The+Grange+Hall/@52.9462607,-1.039641,15z/data=!4m6!3m5!1s0x4879c3c6769e5de5:0x1b9461df3071fcb7!8m2!3d52.9462607!4d-1.039641!16s%2Fg%2F12lsz02qr?entry=ttu"
            }
          />
          <LocationCard
            imageName={"costa-coffee"}
            locationName={"Costa"}
            address={"3 Main Rd, Radcliffe on Trent, NG12 2FD"}
            day={"Sunday / Holiday time"}
            timeslot={"1.30pm - 3.00pm"}
            mapLink={
              "https://www.google.com/maps/place/Costa+Coffee/@52.9469112,-1.0410097,17z/data=!3m1!4b1!4m6!3m5!1s0x4879c5d05229ff53:0x8fb815982aa29f28!8m2!3d52.946908!4d-1.0384348!16s%2Fg%2F11cmrz_v2n?entry=ttu"
            }
          />
        </Box>
      </Box>
    </Stack>
  );
};
