import { Box, Button, Typography, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import MapIcon from "@mui/icons-material/Map";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SchoolIcon from "@mui/icons-material/School";
import CoffeeIcon from "@mui/icons-material/Coffee";
import { useWindowDimensions } from "../../utils/mediaQuery";
import { addresses, dayNames, formatDate } from "../../utils/dateUtils";

const coloursArray = {
  grey: "141,141,141",
  primary: "121,88,163",
  secondary: "255, 115,0",
};
export const MeetingCard = ({
  title,
  locationName,
  date,
  transitionWidth,
  type,
}) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  console.log(locationName);
  return (
    <Box
      style={{ width: width > 768 ? "400px" : "100%" }}
      className={width < transitionWidth ? "bubble-under" : "bubble-side"}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          gap: "1rem",
          width: "100%",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          {title}
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <BusinessIcon />
          <Typography>{locationName}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
            cursor: "text",
          }}
        >
          <LocationOnIcon />
          <Typography>{addresses[locationName]}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          {type === "workshop" ? <SchoolIcon /> : <CoffeeIcon />}
          <Typography>{type}</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <AccessTimeIcon />
          <Typography>{formatDate(date)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
