export const meetings2024 = [
  {
    date: new Date("2024-05-19T13:30:00"),
    locationName: "Costa",
    workshop: false,
  },
  {
    date: new Date("2024-05-22T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-06-12T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-06-16T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-06-26T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-07-03T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-07-07T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-07-10T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-07-21T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-07-31T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-08-11T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-08-21T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-08-28T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-09-04T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-09-11T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-09-18T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-09-25T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-10-02T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-10-06T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-10-09T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-10-16T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-10-23T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-10-30T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-11-03T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-11-06T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-11-11T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #1",
  },
  {
    date: new Date("2024-11-13T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-11-20T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-11-25T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #2",
  },
  {
    date: new Date("2024-11-27T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-12-01T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2024-12-04T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-12-09T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #3",
  },
  {
    date: new Date("2024-12-11T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2024-12-18T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-01-05T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2025-01-08T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-01-13T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #4",
  },
  {
    date: new Date("2025-01-15T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-01-22T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-01-27T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #5",
  },
  {
    date: new Date("2025-01-29T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-02-02T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2025-02-05T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-02-10T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #6",
  },
  {
    date: new Date("2025-02-12T13:30:00"),
    locationName: "The Grange",
  },
  {
    date: new Date("2025-02-19T13:30:00"),
    locationName: "Costa",
  },
  {
    date: new Date("2025-02-24T18:30:00"),
    locationName: "The Grange",
    workshop: true,
    workshopTitle: "Basic Crochet - Session #7",
  },
  {
    date: new Date("2025-02-26T13:30:00"),
    locationName: "The Grange",
  },
];
