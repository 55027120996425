import { Box, Button, Typography, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import MapIcon from "@mui/icons-material/Map";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useWindowDimensions } from "../../utils/mediaQuery";
import { coloursArray, dayNames, formatDate } from "../../utils/dateUtils";

export const NextMeetCard = ({ locationName, date, title }) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        width: width > 768 ? "400px" : "100%",
        gap: "1rem",
        padding: "1rem",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: `0 0 6px 6px rgb(${coloursArray["grey"]},0.2)`,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "left",
          gap: "1rem",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"} color={"secondary"}>
            {title}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <BusinessIcon />
          <Typography>{locationName}</Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <CalendarMonthIcon />
          <Box>{formatDate(date)}</Box>
        </Box>
      </Box>
    </Box>
  );
};
