// project import
import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// material-ui
import { Link, Stack, useTheme } from "@mui/material";
import { menu } from "./menu";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderDesktop = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <>
      <Stack direction="row" spacing={3} alignItems={"center"}>
        {menu.map((item, index) => (
          <Link
            key={`${item.label}-${index}`}
            className={`header-link ${
              pathname.includes(item.to.toLowerCase()) ? "link-selected" : ""
            }`}
            color={theme.palette.header.text}
            component={RouterLink}
            to={item.to}
            underline="none"
            sx={{
              ":hover": {
                color: theme.palette.header.hover,
              },
            }}
          >
            {item.label}
          </Link>
        ))}
      </Stack>
    </>
  );
};

export default HeaderDesktop;
