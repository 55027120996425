import { useState } from "react";
import Calendar from "react-calendar";
import { meetings2024 } from "../../assets/data/meetings2024";
import "react-calendar/dist/Calendar.css";
import { Box } from "@mui/material";
import { MeetingCard } from "../cards/meetCard";
import { useWindowDimensions } from "../../utils/mediaQuery";

export const CalendarView = () => {
  const [value, onChange] = useState(new Date());
  const { width } = useWindowDimensions();
  const transitionWidth = 992;

  const displayDetails = meetings2024.find((x) => {
    return (
      value.getDay() === new Date(x.date).getDay() &&
      value.getMonth() === new Date(x.date).getMonth() &&
      value.getDate() === new Date(x.date).getDate()
    );
  });

  const setClass = (date) => {
    const dateobj = meetings2024.find((x) => {
      return (
        date.getDay() === new Date(x.date).getDay() &&
        date.getMonth() === new Date(x.date).getMonth() &&
        date.getDate() === new Date(x.date).getDate()
      );
    });

    return !dateobj
      ? "none"
      : dateobj?.workshop === true
      ? "secondary"
      : "primary";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: width < transitionWidth ? "column" : "row",
        flexWrap: "wrap",
        gap: "2rem",
        width: "100%",
        padding: "2rem",
      }}
    >
      <Calendar
        onChange={onChange}
        value={value}
        tileClassName={({ activeStartDate, date, view }) =>
          `calendar-cell calendar-badge-${setClass(date)}`
        }
      />

      <Box>
        {!!displayDetails && (
          <MeetingCard
            title={
              displayDetails?.workshop
                ? displayDetails.workshopTitle
                : "Chat & Craft"
            }
            locationName={displayDetails.locationName}
            date={displayDetails.date}
            transitionWidth={transitionWidth}
            type={!!displayDetails.workshop ? "workshop" : "social"}
          />
        )}
      </Box>
    </div>
  );
};
