import { Typography } from "@mui/material";

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const coloursArray = {
  grey: "141,141,141",
  primary: "121,88,163",
  secondary: "255, 115,0",
};

export const addresses = {
  "The Grange":
    "Dowson room, Vicarage Ln, Radcliffe on Trent, Nottingham NG12 2FB",
  Costa: "3 Main Rd, Radcliffe on Trent, NG12 2FD",
};

export const formatDate = (i) => (
  <Typography>{`${dayNames[new Date(i).getDay()]} ${new Date(
    i
  ).getDate()} ${new Date(i).toLocaleString("default", {
    month: "long",
  })} - ${new Date(i).getHours()}:${new Date(i).getMinutes()} `}</Typography>
);

export const getDay = (i) => dayNames[new Date(i).getDay()];
