import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Hero } from "../components/hero/Hero";
import { HomeCard } from "../components/cards/homeCard";
import { HomeMessage } from "../components/cards/homeMessage";
// import { YarnDivider } from "../components/hero/YarnDivider";
import { useNavigate } from "react-router-dom";
// import { useWindowDimensions } from "../utils/mediaQuery";

export const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const { width } = useWindowDimensions();
  return (
    <Stack>
      <Hero />
      <Box
        pl={"3rem"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          width: "100%",
          padding: "2rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant={"h2"} color={theme.palette.primary.main}>
            Welcome!
          </Typography>
          <Typography variant={"h5"} color={theme.palette.primary.main}>
            Join your community knitting group in Radcliffe on trent.
          </Typography>
        </Box>{" "}
      </Box>
      <HomeMessage
        imageName={"knit-group-2"}
        text1={
          "Whether you're a newbie or an experience knitter, every one is welcome, regardless of their age, gender or needs. We're a very friendly and supportive group."
        }
        text2={
          "But we're not just about knitting, we welcome anyone who is into crochet, embroidery or sewing! Any craft that has to do with textiles and yarns"
        }
      />

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "stretch",
          gap: "2rem",
          backgroundColor: theme.palette.grey[100],
          padding: "2rem 1rem",
        }}
      >
        <HomeCard
          imageName={"learn-session-2"}
          text1={"Our meetups"}
          text2={
            "Come join us for one of our relaxed weekly sessions or one of our workshops."
          }
          colour={"primary"}
          button={{
            label: "See schedule",
            onClick: () => navigate("./schedule"),
          }}
        />
        <HomeCard
          imageName={"village-show-knitting"}
          text1={"Village show"}
          text2={
            "See the village show categories for knitting and crochet for the yearly Radcliffe on Trent village show. The 2025 categories are here!"
          }
          colour={"secondary"}
          button={{
            label: "More info",
            onClick: () => navigate("./projects"),
          }}
        />
        <HomeCard
          imageName={"project-yarn"}
          text1={"Charity projects"}
          text2={
            "Each season, we have a feature project which is often a charity project looking to provide to local hospitals or institutions."
          }
          colour={"primary"}
          button={{
            label: "Explore patterns",
            onClick: () => navigate("./projects"),
          }}
        />
      </Box>
      <HomeMessage
        imageName={"knit-nottingham-logo"}
        text1={
          "We partner closely with our local yarn shop, Knit Nottingham, who offers a great range of yarns and worshops and is very involved in the local knitting community."
        }
        text2={
          "The shop is conveniently located right in the city centre of Nottingham, and keeps the community informed of new arrivals and special offers via their newsletter."
        }
        button={{
          label: "Go to partner site",
          onClick: () => window.open("https://knitnottingham.co.uk/", "_blank"),
        }}
      />
    </Stack>
  );
};
